<template>
  <panel
    :title="title"
    :breadcrumbs="breadcrumbs"
  >
    <v-card>
      <v-alert
        :value="true"
        color="info"
        icon="info"
      >
        No payment will be made for this incentive. The displayed Activation incentive has been received by dealer in cash upon successful registration.
      </v-alert>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          lazy
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2025-02"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          placeholder="Search"
          clearable
        />
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :pagination.sync="pagination"
        class="px-1 pb-1"
        item-key="msisdn"
      >
        <template
          slot="headers"
          slot-scope="props"
        >
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="headerClass(header)"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon
                v-if="header.sortable"
                small
              >
                arrow_upward
              </v-icon>
            </th>
          </tr>
        </template>
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.plan }}
          </td>
          <td class="text-xs-left">
            {{ props.item.amount }}
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td class="text-xs-right primary--text">
              Total:
            </td>
            <td class="text-xs-left primary--text">
              RM {{ totalAmount }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const pageTitle = 'Black AI Payment'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      menu: false,
      month: null,
      monthOf: null,
      maxDate: '',
      title: pageTitle,
      noDataText: null,
      noResultText: 'No data that fits that criteria.',
      totalAmount: 0,
      items: [],
      loading: false,
      search: '',
      totalItems: 0,
      pagination: {},
      error: null,
    }
  },
  computed: {
    headers: function () {
      const headers = [
        {
          align: 'text-xs-left',
          sortable: false,
          text: 'Date',
          value: 'date',
        },
        {
          align: 'text-xs-left',
          sortable: false,
          text: 'Msisdn',
          value: 'msisdn',
        },
        {
          align: 'text-xs-left',
          sortable: false,
          text: 'Plan',
          value: 'plan',
        },
        {
          align: 'text-xs-left',
          sortable: false,
          text: 'Amount',
          value: 'amount',
        },
      ]
      return headers
    },
  },
  watch: {
    monthOf: function (val) {
      this.getBlackPayment(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },

  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2025, 2, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getBlackPayment (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.$rest.get('getBlackAiPayment.php', params)
        .then((response) => {
          this.items = response.data.items
          this.totalAmount = response.data.totalAmount
          this.noDataText = "There's nothing to show."
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.noDataText = error.message + ': ' + error.response.data
          this.loading = false
        })
    },
    headerClass (header) {
      const headerClass = ['column']
      header.align && headerClass.push(header.align)
      header.sortable && headerClass.push('sortable')
      header.sortable && (this.pagination.descending
        ? headerClass.push('desc') : headerClass.push('asc'))
      header.sortable && header.value === this.pagination.sortBy &&
        headerClass.push('active')

      return headerClass
    },
  },
}
</script>
